import React from 'react';
import ContentWrapper from '../../Components/Layout/ContentWrapper';
import {Heading, Button, Text, Row} from '../../Widgets';
import {Link} from 'gatsby';
import Item from '../../Components/BlogItem';
import {Row as AntRow, Col} from 'antd';
import useBlogList from '../../hooks/use-blog-list';
const appConfig = require('../../data.json');

function Hashtag({selected, hashtag}) {
  return (
    <div
      style={{
        backgroundColor: selected ? appConfig.colors.second : 'transparent',
        border: selected ? 0 : 'solid 1px #989898',
        padding: '5px 10px',
        margin: '0 8px 8px 0',
      }}>
      <Link
        to={`/articles/?hashtag=${hashtag}`}
        style={{
          color: selected ? 'white' : '#989898',
        }}>
        {hashtag}
      </Link>
    </div>
  );
}

export default function BlogList(props) {
  const {label, hashtag} = props.pageContext;

  const {records, labels, bottomItemOrientation} = useBlogList({label});

  return (
    <>
      <ContentWrapper>
        <div style={{textAlign: 'center', marginBottom: 30}}>
          <Heading
            style={{
              padding: 10,
              borderBottom: '1px solid #eee',
              display: 'inline',
            }}>
            {label || '分類文章'}
          </Heading>
        </div>
        <Row style={{justifyContent: 'center', marginBottom: 30}}>
          {['全部文章', ...labels].map((_label, idx) => {
            const type =
              label && label === _label
                ? 'primary'
                : !label && idx === 0
                ? 'primary'
                : 'default';

            return (
              <Button
                key={_label}
                style={{
                  margin: 10,
                  padding: '4px 15px',
                  height: 32,
                  lineHeight: 1.5715,
                  borderRadius: appConfig.blogLabelRadius,
                  border: appConfig.blogLabelBorder,
                }}
                type={type}>
                <Link to={idx === 0 ? `/articles` : `/articles/${_label}`}>
                  {_label}
                </Link>
              </Button>
            );
          })}
        </Row>

        <AntRow
          gutter={[
            {sm: 12, md: 25, lg: 40},
            {sm: 12, md: 25, lg: 40},
          ]}
          style={{alignSelf: 'stretch', marginBottom: 50}}>
          {records.map((record) => (
            <Col xs={24} sm={12} md={8} key={record.id}>
              <Item
                key={record.id}
                record={record}
                extraStyle={{}}
                type="portrait"
              />
            </Col>
          ))}
        </AntRow>

        {/* <div style={{ backgroundColor: "#f4f4f4", padding: "30px 40px", marginBottom: 50 }}>
          <h3 style={{ textAlign: "center", color: appConfig.colors.text }}>標籤 TAGS</h3>
          <div style={{
            display: "flex",
            flexWrap: "wrap"
          }}>
            {['hashtag1', 'hashtag2', 'hashtag3', 'hashtag4', 'hashtag5'].map((tag, idx) => {
              const selected =
                (hashtag && hashtag === tag) || (!hashtag && idx === 0);
              return (
                <Hashtag hashtag={tag} selected={selected} idx={idx} key={idx} />
              );
            })}
          </div>
        </div> */}
      </ContentWrapper>
    </>
  );
}
